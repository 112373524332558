import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import TopImage from './images/history_1.png';

class FullTimePosition extends Component {

    constructor() {
        super();
    }

    render() {
        return (
            <Row className="px-md-5 mx-5">
                <Col md={12} className={"my-5"}>
                    <img src={TopImage} alt="Overview" />
                </Col>
                <Col md={12} className={"mt-5"}>
                    <Row>
                        <Col md={10} className={"row offset-1"}>
                            <div className={"orangeBg"} style={{ width: 40 }}></div>
                            <Col md={8}>
                                <p className={"h1 b text-left pt-5"}>
                                    OUR HISTORY
                                </p>
                            </Col>
                            <Col md={12} className={"pt-5 mt-5"} style={{ marginLeft: 40 }}>
                                <p className={"h3 b text-left"}>
                                    DMA was established in 1989, as a diversified archtectural firm as the corner-stone for the DMG foundation: one of the leading entites in Real Estate Development in Egypt.
                                </p>
                                <p className={"h3 b text-left"}>
                                    DMA enjoys being a strong Engineering consultancy which provides solutions to our client weather it is design, engineering services or supervision with it is approact of projects delivering. In collaboration between the staff members, a project is materialized from concept to design.
                                </p>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default FullTimePosition;
