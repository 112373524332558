import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { getPeopleAction } from '../../Redux/Actions/data.actions';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'animate.css/animate.min.css';
import './people.styles.css';

const loader = {
    margin: '3rem',
    transition: '3s'
}
class People extends Component {
    constructor(props) {
        super(props)
        this.state = {
            position: '',
            service: '',
            filteredPeople: []
        }
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }
    async componentDidMount() {
        this.props.getPeople();
        this.setState({ filteredPeople: await this.props.getPeople().then(res => res.payload) }, () => {
            console.log('state: ', this.state);
        })
    }
    handleFilterChange({ currentTarget: select }) {
        const { name, value } = select;
        const state = { ...this.state };
        if (value === '') {
            return this.setState({ filteredPeople: this.props.people }, () => {
                console.log(this.state);
            });
        }
        state[name] = value;
        state.filteredPeople = this.props.people.filter((item) => {
            return item[name] === value;
        })
        this.setState({ ...state }, () => {
            console.log(this.state);
        });
    }
    render() {
        const { loading } = this.props;
        if (loading) {
            return (
                <Loader type='Oval' color='#010039' height={100} width={100} style={loader} />
            );
        }
        return (
            <div className="container">
                <div className="filters">
                    <select
                        name="position"
                        className="form-control col-lg-5"
                        value={this.state.title}
                        onChange={this.handleFilterChange}
                    >
                        <option value=''>Title...</option>
                        {this.props.people.map((item, index) => {
                            return (
                                <option value={item.position} key={index}>
                                    {item.position}
                                </option>
                            );
                        })}
                    </select>
                    <select
                        name="service"
                        className="form-control col-lg-5"
                        value=''
                        onChange={this.handleFilterChange}
                    >
                        <option value=''>Service...</option>
                        {this.props.people.map((item, index) => {
                            return item.service ? (
                                <option value={item?.title} key={index}>
                                    {item?.title}
                                </option>
                            ) : null
                        })}
                    </select>
                </div>
                <Row className="mt-4">
                    {
                        this.state.filteredPeople.map((item, index) => {
                            return (
                                <div key={index} className="col-4 people-card-img">
                                    <img src={item.image} alt="profile" className="img-fluid" />
                                    <div className="card-details">
                                        <h3><Link to={{
                                            pathname: '/PeopleSingle',
                                            state: {
                                                people: item
                                            }
                                        }}>{item.name}</Link></h3>
                                        <p>{item.position}</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    people: state.people,
    loading: state.loading
})

const mapDispatchToProps = dispatch => ({
    getPeople: () => dispatch(getPeopleAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(People);
