import React, { Component } from "react";
import { Row, Col, Container, DropdownButton, Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import CardList from "../CardList/cardList.component";
import {
  getFilterListAction,
  getProjectsAction,
  getSectorsAction
} from "../../Redux/Actions/data.actions";
const loader = {
  margin: "3rem",
  transition: "3s",
};
class Filter extends Component {
  constructor(props) {
    super(props);
    this.HandleSearch = this.HandleSearch.bind(this);
  }

  componentDidMount() {
    this.props.getFilterList();
    if (this.props.match.params.filterType === "scope") {
      this.props.filterProjectsByScope(this.props.match.params.id);
    } else if (this.props.match.params.filterType === "sector") {
      this.props.filterProjectsBySector(this.props.match.params.id);
    } else {
      this.props.getProjects();
    }
  }

  componentDidUpdate() {
    this.HandleOnChange();
  }

  HandleOnChange() {
    const filters = document.querySelectorAll(".filters select");
    let arrData = [];
    filters.forEach((item) => {
      item.addEventListener("change", (e) => {
        arrData = [];
        filters.forEach((itemx) => {
          if (!itemx.value.includes("..")) arrData[itemx.name] = itemx.value;
        });
        this.props.filterProjects(arrData);
      });
    });
  }

  HandleSearch(e) {
    this.props.searchTerm(e.target.value);
  }

  render() {
    if (this.props.loading) {
      return (
        <>
        <div className="filters">
        	{Object.keys(this.props.filterList).map(
        		select => {
        			return (
        				<select
        					name={select}
        					className="form-control"
        				>
        					<option selected>{select}..</option>
        					{this.props.filterList[select].map(option => {
        						return (
        							<option value={option}>
        								{option}
        							</option>
        						);
        					})}
        				</select>
        			);
        		}
        	)}
        </div>

        <Loader
          type="Oval"
          color="#010039"
          height={100}
          width={100}
          style={loader}
        />
        </>
      );
    }

    return (
      <div className="filter-container">
        <hr />
        <Container fluid>
          <Row className="filters">
            {Object.keys(this.props.filterList).map((select) => {
              return (
                <Col key={select} xs={12} md={12} lg={2}>
                  <select name={select} className="form-control">
                    <option value="..">{select}..</option>
                    {this.props.filterList[select].map((option) => {
                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              );
            })}
            <Col>

              <input
                type="text"
                name="txtSearch"
                id="txtSearch"
                className="form-control"
                placeholder="Search Projects"
                onChange={this.HandleSearch}
                defaultValue={this.props.searchTerm}
              />
            </Col>
          </Row>
        </Container>
        <hr />
        <CardList data={this.props.projects} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  projects: state.projects,
  filterList: state.filterList,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getFilterList: () => dispatch(getFilterListAction()),
  getSectors: () => dispatch(getSectorsAction()),
  getProjects: () => dispatch(getProjectsAction()),
  searchTerm: (term) => dispatch(getProjectsAction(term, null)),
  filterProjects: (filters) => dispatch(getProjectsAction(null, filters)),
  filterProjectsByScope: (id) => dispatch(getProjectsAction(null, null, id)),
  filterProjectsBySector: (id) =>
    dispatch(getProjectsAction(null, null, null, id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filter));
