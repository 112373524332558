import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import PageHeader from "../../Components/PageHeader/pageHeader.component";
import callisonrtkl from './images/callisonrtkl.png';
import PerkinsEastman from './images/perkins-eastman.jpg';
import col2 from './images/04.jpg';
import col3 from './images/1.jpg';
import 'animate.css/animate.min.css';
import './collaboration.styles.css';


class Collaboration extends Component {
    constructor() {
        super();
        this.state = {
            pageHeader: {
                title: 'Collaborations'
            }
        }
    }
    render() {
        const { pageHeader } = this.state;
        return (
            <div>
                <PageHeader data={pageHeader} />
                <Container fluid className='mt-5 mb-50'>
                    <Row>
                        <Col md='7' className='text-left'>
                            <ScrollAnimation animateIn="fadeIn">
                                <h2 className="title-general mb-50 text-white">Collaboration</h2>
                                <p className="text-white mb-50">
                                    Our dedication to uncover the most insightful and impactful solutions for our clients
                                    lead us to conduct a Strategic Alliance  with different international consultants,
                                    this Alliances creates a streamlined and efficient process, offering comprehensive
                                    analytics-driven project phases, cost reduction, management of very limited time constraints,
                                    along with the understanding of local nature, codes and regulations, and market which all takes
                                    part improving customer experience to deliver outstanding solutions.
                                </p>
                                <p><img className='alignnone  wp-image-1682 mb-50' src={callisonrtkl} alt='' /></p>
                                <p className="text-white mb-50">
                                    In 2015, CallisonRTKL and DMA conducted a Strategic Alliance that creates a sustainable
                                    smart model between Global exposure and Local presence. Our devotion to teamwork and putting
                                    the interest of the team ahead of ours establishes a framework that utilizes our
                                    multidisciplinary expertise, by the Exchange of staff between both offices in Egypt and the USA,
                                    working together as a single team.
                                </p>
                                <p><img className='alignnone  wp-image-1682 mb-50' src={PerkinsEastman} alt='' /></p>
                                <p className="text-white">
                                    Perkins Eastman and DMA, initiated a diverse team of specialists achieving design excellence and
                                    successful implementation that emphasizes an ability to work alongside the most experienced professionals,
                                    through continuous project-oriented workshops that go effectively with our clients.
                                </p>
                            </ScrollAnimation>
                        </Col>
                        <Col md='5'>
                            <ScrollAnimation animateIn="fadeIn">
                                <p><img className="w-100" src={col2} alt="" /></p>
                                <p><img className="w-100" src={col3} alt="" /></p>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default Collaboration;

