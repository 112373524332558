import React from 'react';
import {Row} from 'react-bootstrap';
import Card from '../Card/card.component';


const CardList  = ({data}) =>{
    return(
        <Row className='projects-items p-5'>
            {
                data.map(card => {
                return( <Card key={card.id} info={card}/>);})
            }
        </Row>
    );
}
export default CardList;
