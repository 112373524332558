import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import ScrollAnimation from "react-animate-on-scroll";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "animate.css/animate.min.css";
import "../../Components/People/people.styles.css";
import quoteImg from "./quote.jpg";

class PeopleSingle extends Component {
  constructor(props) {
    super();
  }
  render() {
    const { id, image, name, position, description, quote } = this.props.location.state.people;
    return (
      <div>
        <Container className="mt-3 mb-5">
          <div key={id} className="item">
            <Row>
              <Col md="4" className="pull-left">
                <div className="img-block mb-3">
                  <LazyLoadImage src={image} alt="" width="100%" effect="blur" />
                  <div className="m-3 p-5" style={{ backgroundImage: `url(${quoteImg})`, backgroundSize: "cover", width: "320px", height: "320px" }}>
                    <p className="m-3">{quote}</p>
                  </div>
                </div>
              </Col>
              <Col md="8">
                <ScrollAnimation animateIn="fadeIn">
                  <div className="title text-left">
                    <h2 className="mt-2">{name}</h2>
                    <h4>{position}</h4>
                  </div>
                  <div className="text-left m-3">
                    {ReactHtmlParser(description)}
                  </div>
                </ScrollAnimation>
              </Col>
            </Row>
          </div>
        </Container>
      </div >
    );
  }
}
export default PeopleSingle;
