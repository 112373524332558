export const getAllProjectsAction = 'action=getAllProjects';
export const getFilterListAction = 'action=getFilterList2';
export const getWhatWeDoAction = 'action=getWhatWeDo';
export const getAllNewsAction = 'action=getAllNews';
export const getNewsFilterListAction = 'action=getNewsFilterList';
export const getPeopleAction = 'action=getPeople';
export const getSliderAction = 'action=getSlider';
export const getScopesAction = 'action=getScopes';
export const getSectorsAction = 'action=getSectors';
export const getLatesWorkAction = 'action=getLatestWork';
export const getClientsAction = 'action=getClients';
export const getAllCareersAction = 'action=getAllCareers';
export const postFullTimePositionAction = 'action=postFullTimePosition';
export const postInternShipAction = 'action=postInerShip';