import React from "react";
import ReactHtmlParser from "react-html-parser";
import './projectDetails.styles.css';

const Projectdetails = props => {

    let scopes = [];
    let scopeIDs = [];
    let sectors = [];
    let sectorIDs = [];

    const { title, description, city, country, scope, scope_id, sector, sector_id, client, size, duration, location } = props.data;
    if (scope) {
        scopeIDs = scope_id.split(',');
        scopes = scope.split(',');
        scopes = scopes.map((value, id) => '<a class="text-secondary" href="/Projects/scope/' + scopeIDs[id] + '">' + value + '</a>');
    }

    if (sector) {
        sectorIDs = sector_id.split(',');
        sectors = sector.split(',');
        sectors = sectors.map((value, id) => '<a class="text-secondary" href="/Projects/sector/' + sectorIDs[id] + '">' + value + '</a>');
    }

    return (
        <div className='text-left'>
            <div className="project-heading mt-3">
                <h1 style={{ color: "rgb(24, 30, 64)" }}>{title}</h1>
                <p>{city + ", " + country}</p>
            </div>
            <div className="container mt-5 col-12">
                <div className="row">
                    <div className="col-3">
                        <div className="data-line">
                            <span style={{ color: '#d98032' }}>Client:</span><br/>
                            <p style={{ color: 'rgb(111, 116, 124)' }}>{client}</p>
                        </div>
                        <div className="data-line">
                            <span style={{ color: '#d98032' }}>Sector:</span><br />
                            <p style={{ color: 'rgb(111, 116, 124)' }}>{sectors.map(value => ReactHtmlParser(value))}</p>
                        </div>
                        <div className="data-line">
                            <span style={{ color: '#d98032' }}>Scope:</span><br />
                            <p style={{ color: 'rgb(111, 116, 124)' }}>{scopes.map(value => ReactHtmlParser(value))}</p>
                        </div>
                        <div className="data-line">
                            <span style={{ color: '#d98032' }}>Project Size:</span><br/>
                            <p style={{ color: 'rgb(111, 116, 124)' }}>{size}</p>
                        </div>
                        {duration ?
                            <div className="data-line">
                                <span style={{ color: '#d98032' }}>Duration:</span><br/>
                                <p style={{ color: 'rgb(111, 116, 124)' }}>{duration}</p>
                            </div> : null}
                        {location ?
                            <div className="data-line">
                                <span style={{ color: '#d98032' }}>Location:</span><br/>
                                <p style={{ color: 'rgb(111, 116, 124)' }}>{location}</p>
                            </div> : null}
                    </div>
                    <div class="col-1-custom" style={{ borderRight: "2px solid #d98032" }} />
                    <div className="col-8 pl-4" style={{ marginTop: 10 }}>
                        <p>{ReactHtmlParser(description)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Projectdetails;
