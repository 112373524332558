  import React, { Component } from "react";
  import { Navbar, Nav } from "react-bootstrap";
  import { Link } from "react-router-dom";
  import logo from "./images/logo.png";

  class Header extends Component {
    constructor(props) {
      super();

      this.state = {
          activePage: ""
      }
    }

    componentDidMount() {
      switch (window.location.pathname){
        case "/aboutDma":
          this.setState({ activePage: "about" });
          break;
        case "/projects":
          this.setState({ activePage: "projects" });
          break;
        case "/careers":
          this.setState({ activePage: "careers" });
          break;
        case "/ApplicationForm":
          this.setState({ activePage: "careers" });
          break;
        case "/news":
          this.setState({ activePage: "news" });
          break;
        case "/contactUs":
          this.setState({ activePage: "contact" });
          break;
        default:
          break;
      }
    }

    render() {
      return (
        <Navbar collapseOnSelect expand="lg" sticky="top" variant="light">
          <Navbar.Brand href="/" className="logo">
              <img width="150" alt="DMA Design Consultants" src={logo} />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <Nav.Item>
                <Link
                  to={{pathname: "/aboutDma"}}
                  className={"nav-link" + (this.state.activePage == "about" ? " active" : "")}
                  onClick={() => this.setState({ activePage: "about" })}
                >
                  About
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link
                  to={{pathname: "/projects"}}
                  className={"nav-link" + (this.state.activePage == "projects" ? " active" : "")}
                  onClick={() => this.setState({ activePage: "projects" })}
                >
                  Projects
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link
                  to={{pathname: "/careers"}}
                  className={"nav-link" + (this.state.activePage == "careers" ? " active" : "")}
                  onClick={() => this.setState({ activePage: "careers" })}
                >
                  Careers
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link
                  to={{pathname: "/news"}}
                  className={"nav-link" + (this.state.activePage == "news" ? " active" : "")}
                  onClick={() => this.setState({ activePage: "news" })}
                >
                  News
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link
                  to={{pathname: "/contactUs"}}
                  className={"nav-link" + (this.state.activePage == "contact" ? " active" : "")}
                  onClick={() => this.setState({ activePage: "contact" })}
                >
                  Contact us
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      );
    }
  }
  
  export default Header;
