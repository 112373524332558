import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Nav } from "react-bootstrap";
import logo from "./images/logo.png";
import emailjs from "emailjs-com";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.handleSendMail = this.handleSendMail.bind(this);
  }

  sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "sendinblue",
        "template_5NU78eGn",
        e.target,
        "user_AV6kA6k1QV0bN6VNUeVjg"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  handleSendMail(e) {
    e.preventDefault();
    const name = document.getElementById("txtName");
    const subject = document.getElementById("txtSubject");
    const email = document.getElementById("txtEmail");
    const phone = document.getElementById("txtPhone");
    const message = document.getElementById("txtMessage");

    var templateParams = {
      name: name,
      subject: subject,
      email: email,
      phone: phone,
      message: message,
    };

    emailjs
      .send("user_AV6kA6k1QV0bN6VNUeVjg", "template_5NU78eGn", templateParams)
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  }

  render() {
    return (
      <Container fluid="true" className="copyrights">
          <Row className="no-gutters">
            <Col xs={12} sm={6} className="flex-container">
              <Link to="/">
                <img
                  width="150"
                  className="d-inline-block align-top"
                  alt="DMA Design Consultants"
                  src={logo}
                />
              </Link>
            </Col>
            <Col xs={12} sm={6}>
              <div className="footer-social text-white d-inline-block float-right">
                <Nav>
                  <Nav.Item>
                    <Nav.Link
                      className="fb"
                      href="https://www.facebook.com/DMA-123149804426403/"
                    ></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="lkln"
                      eventKey="link-1"
                      href="https://www.linkedin.com/company/dma-egypt/"
                    ></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="ins"
                      eventKey="link-2"
                      href="https://www.instagram.com/dma.design.consultancy/"
                    ></Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <br/>
              {/* <p className="copyright d-inline-block text-white text-center mt-5">Copyright 2020. All rights reserved by DMA</p> */}
            </Col>
          </Row>
        </Container>
    );
  }
}

export default Footer;
