import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
// import shortid from "shortid";
import ReactHtmlParser from "react-html-parser";
import PageHeader from "../../Components/PageHeader/pageHeader.component";
import "animate.css/animate.min.css";
import "./whatWeDo.styles.css";

class WhatWeDo extends Component {
	constructor(props) {
		super();
	}

	render() {
		const { pageHeader, description, projects } = this.props.location.state;
		return (
			<div>
				<PageHeader data={pageHeader} />
				<Container fluid className="mt-5">
					<Row className="text-left text-white mt-3 bt-5">
						<Col md="12">
							<ScrollAnimation animateIn="fadeIn">
								<h2 className="title-general mb-5 text-left">
									<b>{description.title}</b>
								</h2>
								<Container>
									{ReactHtmlParser(description.description)}
								</Container>
							</ScrollAnimation>
						</Col>
						<Col>
							<div>
								<h2 className="title-general text-white mt-3 mb-5">
									OUR Projects
								</h2>
								<div className="row projects-items">
									{projects.map((item) => (
                                        <Col xs={12} sm={6} md={6} lg={3} className="item-block p-1">
                                            <div className="card-container" title={item.title}>
                                                <img src={item.outer_image} alt="project" className="card-img" />
                                                <Link
                                                    style={linkStyle}
                                                    to={{
                                                        pathname: `/Projects/${item.id}`,
                                                        state: item,
                                                    }}
                                                >
                                                    <div className="hoverDiv">
                                                        <div className="item-trans text-left">
                                                            <span>{item.year}</span>
                                                            <div style={{ height: "50%" }}>
                                                                <h1>{item.title}</h1>
                                                                <span>{item.sector}</span>
                                                                <hr className="border border-light" />
                                                                <p>{item.short_description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </Col>
									))}
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
let linkStyle = {
	textDecoration: "none",
	color: "#fff",
};
export default WhatWeDo;
