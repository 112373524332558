import React, { Component } from "react";
import imgc1 from "./images/c1.png";
import imgc2 from "./images/c2.png";
import imgc3 from "./images/c3.png";
import imgc4 from "./images/c4.png";
import imgc5 from "./images/c5.png";


class FooterCustomer extends Component {
  render() {
    return (
      <footer>
        <div className="footer-content">
          <h3>Our Customers</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          </p>
          <ul className="socials">
            <li>
              <img className="img-fluid" src={imgc1}></img>
            </li>
            <li>
              <img className="img-fluid" src={imgc2}></img>
            </li>
            <li>
              <img className="img-fluid" src={imgc3}></img>
            </li>
            <li>
              <img className="img-fluid" src={imgc4}></img>
            </li>
            <li>
              <img className="img-fluid" src={imgc5}></img>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}

export default FooterCustomer;
