import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageHeader from "../../Components/PageHeader/pageHeader.component";
import FullTimePosition from "./fullTimePosition.page";
import Internship from "./internship.page";

class AboutDma extends Component {
    constructor() {
        super();
        this.state = {
            pageHeader: {
                title: 'Careers',
                breadcrumb: "Careers",
                formNeeded: <FullTimePosition />,
                fullActive: " active",
                internActive: ""
            }
        }
    }

    componentDidMount() {
        this.setState({ formNeeded: <FullTimePosition />, fullActive: " active", internActive: "" })
    }

    render() {

        return (
            <div>
                <PageHeader data={this.state.pageHeader} />
                <Container fluid className="my-5 mx-md-3">
                    <Row>
                        <Col md={8}>
                            <Row className={"text-left"}>
                                <Col md={6}>
                                    <h3><a className={"href"} href={"/#careers/"}>{"Open Positions"}</a></h3>
                                </Col>
                                <Col md={6}>
                                    <h3><a className={"href active"} href={"/#applicationForm"}>{"Application Form"}</a></h3>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className={"mt-5"}>
                        <Col md={8}>
                            <Row className={"text-left"}>
                                <Col md={6}>
                                    <h3><a className={"href cursor" + this.state.fullActive} onClick={() => this.setState({ formNeeded: <FullTimePosition />, fullActive: " active", internActive: "" })}><label class="container">Full time position<input type="radio" name="radio" /><span class="checkmark"></span></label></a></h3>
                                </Col>
                                <Col md={6}>
                                    <h3><a className={"href cursor" + this.state.internActive} onClick={() => this.setState({ formNeeded: <Internship />, fullActive: "", internActive: " active"  })}><label class="container">Internship<input type="radio" name="radio" /><span class="checkmark"></span></label></a></h3>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {this.state.formNeeded}

                </Container>
            </div>
        );
    }
}

export default AboutDma;
