import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

class Internship extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            f_name: "",
            l_name: "",
            faculty: "",
            univerisity: "",
            c_skills: "",
            department_required: "",
            cv_attached: "",
            reuest_for: "internship"
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({
          [name]: value
      });
    }

    handleSubmit(event) {
        event.preventDefault();
        console.log(this.state);
    }

    render() {
        return (
          <form method={"post"} onSubmit={this.handleSubmit}>
              <Row className={"pt-5"}>
                  <Col md={8}>
                      <div className={"row"}>
                          <div className={"col-12 col-md-3 text-left text-md-right"}>
                              <label for="email">E-mail: </label>
                          </div>
                          <div className={"col-12 col-md-6"}>
                              <input type="email" className={"form-control"} name={"email"} id={"email"} value={this.state.email} onChange={this.handleInputChange}/>
                          </div>
                      </div>
                      <div className={"row mt-3"}>
                          <div className={"col-12 col-md-3 text-left text-md-right"}>
                              <label for="f_name">First name: </label>
                          </div>
                          <div className={"col-12 col-md-6"}>
                              <input type="text" className={"form-control"} name={"f_name"} id={"f_name"} value={this.state.f_name} onChange={this.handleInputChange}/>
                          </div>
                      </div>
                      <div className={"row mt-3"}>
                          <div className={"col-12 col-md-3 text-left text-md-right"}>
                              <label for="l_name">Last name: </label>
                          </div>
                          <div className={"col-12 col-md-6"}>
                              <input type="text" className={"form-control"} name={"l_name"} id={"l_name"} value={this.state.l_name} onChange={this.handleInputChange}/>
                          </div>
                      </div>
                      <div className={"row mt-3"}>
                          <div className={"col-12 col-md-3 text-left text-md-right"}>
                              <label for="faculty">Faculty: </label>
                          </div>
                          <div className={"col-12 col-md-6"}>
                              <input type="text" className={"form-control"} name={"faculty"} id={"faculty"} value={this.state.faculty} onChange={this.handleInputChange}/>
                          </div>
                      </div>
                      <div className={"row mt-3"}>
                          <div className={"col-12 col-md-3 text-left text-md-right"}>
                              <label for="univerisity">Univerisity: </label>
                          </div>
                          <div className={"col-12 col-md-6"}>
                              <input type="text" className={"form-control"} name={"univerisity"} id={"univerisity"} value={this.state.univerisity} onChange={this.handleInputChange}/>
                          </div>
                      </div>
                      <div className={"row mt-3"}>
                          <div className={"col-12 col-md-3 text-left text-md-right"}>
                              <label for="c_skills">Computer skills: </label>
                          </div>
                          <div className={"col-12 col-md-6"}>
                              <input type="text" className={"form-control"} name={"c_skills"} id={"c_skills"} value={this.state.c_skills} onChange={this.handleInputChange}/>
                          </div>
                      </div>
                      <div className={"row mt-3"}>
                          <div className={"col-12 col-md-3 text-left text-md-right"}>
                              <label for="department_required">Department required: </label>
                          </div>
                          <div className={"col-12 col-md-6"}>
                              <input type="text" className={"form-control"} name={"department_required"} id={"department_required"} value={this.state.department_required} onChange={this.handleInputChange}/>
                          </div>
                      </div>
                      <div className={"row mt-3"}>
                          <div className={"col-12 col-md-3 text-left text-md-right"}>
                              <label for="cv_attached">Attach CV: </label>
                          </div>
                          <div className={"col-12 col-md-6"}>
                              <input type="file" className={"form-control"} name={"cv_attached"} id={"cv_attached"} value={this.state.cv_attached} onChange={this.handleInputChange}/>
                          </div>
                      </div>
                      <div className={"row mt-md-1 mt-2"}>
                          <div className={"col-12 col-md-6 offset-md-3 text-left color_active"}>
                              <label>* Kindly be informed that we only accept students in the last grade</label>
                          </div>
                      </div>
                      <div className={"row mt-n2"}>
                          <div className={"col-12 col-md-6 offset-md-3 text-left color_active"}>
                              <label>* Kindly be informed that CV must include a recent photo</label>
                          </div>
                      </div>
                      <div className={"row mt-3"}>
                            <div className={"col-12 col-md-6 offset-md-3 text-right"}>
                              <input type={"submit"} className={"btn btn btn-primary"} style={{ paddingTop: 10, paddingBottom: 0 }} value={"Submit"}/>
                          </div>
                      </div>
                  </Col>
              </Row>
          </form>
        );
    }
}

export default Internship;
