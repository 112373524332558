import React, { Component } from 'react';
import People from '../../Components/People/people.component';
import PageHeader from "../../Components/PageHeader/pageHeader.component";


class PeopleList extends Component {
    constructor() {
        super();

        this.state = {
            pageHeader: {
                title: 'Our People'
            }
        }
    }

    render() {
        const {pageHeader} = this.state;
        return (
            <div>
                <PageHeader data={pageHeader} />
                    <People />
            </div>
        );
    }
}
export default PeopleList;