import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import MainImage from './images/header.png';
import MiddleImage from './images/middle.png';

class Collabration extends Component {

    constructor() {
        super();
    }

    render() {
        return (
            <Row>
                <Col md={12} className={"my-5"}>
                    <img src={MainImage} alt="Overview" style={{width: '100%'}}/>
                </Col>
                <Col md={12} className={"mt-5"}>
                    <Row>
                        <Col md={6}>
                            
                        </Col>
                        <Col md={6} className={"row"}>
                            <Col md={1} className={"orangeBg"}></Col>
                            <Col md={10}>
                                <p className={"h1e b text-left"}>
                                    About
                                    <br />
                                    Callison RTKL
                                </p>
                            </Col>
                            <Col md={12} className="mt-5">
                                <p className={"mt-5 b text-left col-md-10 offset-1"}>
                                    <b>CallisonRTKL</b> is a global architecture, planning and design practice. Over the last 75 years, we have created some of the world’s most memorable and successful environments for developers, retailers, investors, institutions and public entities. Our work has set us apart as the number one retail design firm in the world and a top-five architecture practice across multiple disciplines and sectors. Under the Arcadis umbrella, we continue to expand our sphere of influence and the depth and breadth of our resources. Our team of nearly 1,700 professionals around the world is committed to advancing our clients’ businesses and enhancing quality of life. callisonrtkl.com
                                </p>
                            </Col>
                        </Col>
                    </Row>

                    <Row className={"mt-5 pt-5"}>
                        <Col md={4}>
                            <div className={"greyBg py-5"}>
                                <p className={"h1e b text-center"}>
                                    20
                                </p>
                                <p className={"h3 b text-center"}>
                                    Offices worldwides
                                </p>
                            </div>
                        </Col>
                        <Col md={4} className={"py-5  middle-content"}>
                            <p className={"h1e b text-center"}>
                                2015
                            </p>
                            <p className={"h3 b text-center"}>
                                CallisonRTKL merge
                            </p>
                        </Col>
                        <Col md={4}>
                            <div  className={"greyBg py-5"}>
                                <p className={"h1e b text-center"}>
                                    1700+
                                </p>
                                <p className={"h3 b text-center"}>
                                    Professionals worldwide
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className={"my-5 ulinebl"}></div>
                    <Row className={"mb-5"}>
                        <Col md={4}>
                            <div className={"greyBg py-5"}>
                                <p className={"h1e b text-center"}>
                                    #1
                                </p>
                                <p className={"h3 b text-center"}>
                                    Green Resedential Firm
                                </p>
                            </div>
                        </Col>
                        <Col md={4} className={"py-5 middle-content"}>
                            <p className={"h1e b text-center"}>
                                #2
                            </p>
                            <p className={"h3 b text-center"}>
                                Resedential Architecture firm
                            </p>
                        </Col>
                        <Col md={4}>
                            <div className={"greyBg py-5"}>
                                <p className={"h1e b text-center"}>
                                    TOP 5
                                </p>
                                <p className={"h3 b text-center"}>
                                    Architecture firm
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Col md={12} className={"mt-5"}>
                        <img src={MiddleImage} alt="Overview" />
                    </Col>

                    <Row className={"mt-5 pt-5"}>
                        <Col md={12} className={"py-5"}>
                            <p className={"b text-left"}>
                                <b>DMA</b> has announced joining forces with International CallisonRTKL in November 2016 to form a strategic alliance in the field of construction, management of new cities and urban planning in accordance with the global intelligent engineering and design systems.
                            </p>
                            <p className={"b text-left mt-5"}>
                                This Partnership strategy was announced during and expanded conference that was entitled.
                            </p>
                            <p className={"h3 b orangeClr text-center mt-5"}>
                                (Urban planning and the latest global design trend)
                            </p>
                            <p className={"b text-left mt-5"}>
                                And that was in the presence of Ministerial representatives and more than 50 of the leading real estate developers in Egypt.
                            </p>
                        </Col>
                    </Row>

                    <Row className={"mt-5 pt-5"}>
                        <Col md={6} className={"py-5"}>
                            <p className={"h1e b text-center"}>
                                <label className={"uline"}>+12</label>
                            </p>
                            <p className={"h3 b text-center"}>
                                Projects
                            </p>
                            <br /><br />
                            <p className={"h1e b text-center"}>
                                <label className={"uline"}>Sectors</label>
                            </p>
                            <p className={"h3 b text-center"}>
                                Resedential
                                <br /><br />
                                Commercial
                                <br /><br />
                                Retail
                                <br /><br />
                                Offices
                            </p>
                        </Col>
                        <Col md={6} className={"py-5"}>
                            <p className={"h1e b text-center"}>
                                <label className={"uline"}>4</label>
                            </p>
                            <p className={"h3 b text-center"}>
                                Developers
                            </p>
                            <br /><br />
                            <p className={"h1e b text-center"}>
                                <label className={"uline"}>Locations</label>
                            </p>
                            <p className={"h3 b text-center"}>
                                New Cairo
                                <br /><br />
                                6<sup>th</sup> October
                                <br /><br />
                                New Capital
                                <br /><br />
                                Mostakbal City
                                <br /><br />
                                North Coast
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default Collabration;
