import React, { Component } from "react";
import bg from "./images/projects.jpg";
import "animate.css/animate.min.css";

class PageHeader extends Component {

    constructor(props) {
        super();

        this.state = {
            id: "1",
            image: bg,
            title: "",
            breadcrumb: "",
            description: ""
        };
    }

    componentDidMount() {
        const { image, title, breadcrumb, description } = this.props.data;
        this.setState({
            image,
            title,
            breadcrumb,
            description
        });
    }
    componentWillReceiveProps(nextProps) {
        const { image, title, breadcrumb, description } = nextProps.data;
        this.setState({
            image,
            title,
            breadcrumb,
            description
        });
    }

    render() {
        return (
            <div className="text-center">
                <div className="py-5 breadcrumb-custom">
                    <h1 className='text-white mt-3'>{this.state.title}</h1>
                    <p className='text-white mt-3'>{"DMA -> " + this.state.breadcrumb}</p>
                </div>
            </div>
        );
    }
}
export default PageHeader;

