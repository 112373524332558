import React, { Component } from "react";
// import img1 from "./images/11.png";
// import img2 from "./images/22.png";
// import img3 from "./images/33.png";
// import img4 from "./images/44.png";
// import img5 from "./images/55.png";

import img1 from "./images/5.png";
import img2 from "./images/4.png";
import img3 from "./images/3.png";
import img4 from "./images/2.png";
import img5 from "./images/1.png";
import img6 from "./images/6.png";

class HomeCard extends Component {
  render() {
    return (
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 col-md-4 col-xl-2 p-2 c3 c d-flex align-items-center text-white">
            <div>
              <img src={img1} className="img-fluid card-img-top size mb-2" alt="..." />
              <h3 className="font-weight-bold card-title">30+</h3>
              <h5 className="card-title mb-n2">Years in the<br />Market</h5>
            </div>
          </div>
          <div class="col-6 col-md-4 col-xl-2 p-2 c2 c d-flex align-items-center">
            <div>
              <img src={img2} className="img-fluid card-img-top size mb-2" alt="..." />
              <h3 className="font-weight-bold card-title">12,000+</h3>
              <h5 className="card-title">
                Buildings
              </h5>
            </div>
          </div>
          <div class="col-6 col-md-4 col-xl-2 p-2 c1 c d-flex align-items-center">
            <div>
              <img src={img3} className="img-fluid card-img-top size mb-2" alt="..." />
              <h3 className="font-weight-bold card-title">250+</h3>
              <h5 className="card-title">Experts</h5>
            </div>
          </div>
          <div class="col-6 col-md-4 col-xl-2 p-2 c3 c d-flex align-items-center text-white">
            <div>
              <img src={img4} className="img-fluid card-img-top size mb-2" alt="..." />
              <h3 className="font-weight-bold card-title">200+</h3>
              <h5 className="card-title">Projects</h5>
            </div>
          </div>
          <div class="col-6 col-md-4 col-xl-2 p-2 c2 c d-flex align-items-center">
            <div>
              <img src={img5} className="img-fluid card-img-top size mb-2" alt="..." />
              <h3 className="font-weight-bold card-title">100+</h3>
              <h5 className="card-title">Clients</h5>
            </div>
          </div>
          <div class="col-6 col-md-4 col-xl-2 p-2 c1 c d-flex align-items-center">
            <div>
              <img src={img6} className="img-fluid card-img-top size mb-2" alt="..." />
              <h3 className="font-weight-bold card-title">10,000+</h3>
              <h5 className="card-title mb-n2">Acres<br />Developed</h5>
            </div>
          </div>
        </div>
      </div>
      // <div className="card-group">
      //   <div className="card text-white  text-center c1">
      //     <img src={img1} className="img-fluid card-img-top size" alt="..." />
      //     <div className="card-body">
      //       <h3 className="card-title">+30</h3>
      //       <h3 className="card-title">years in market</h3>
      //     </div>
      //   </div>
      //   <div className="card text-white c2">
      //     <img src={img2} className="img-fluid card-img-top size" alt="..." />
      //     <div className="card-body">
      //       <h3 className="card-title">11.500</h3>
      //       <h3 className="card-title">
      //         Acres land bank Developed
      //       </h3>
      //     </div>
      //   </div>
      //   <div className="card text-white c3">
      //     <img src={img3} className="img-fluid card-img-top size" alt="..." />
      //     <div className="card-body">
      //       <h3 className="card-title">+250</h3>
      //       <h3 className="card-title">Mater Mind</h3>
      //     </div>
      //   </div>
      //   <div className="card text-white c1">
      //     <img src={img4} className="img-fluid card-img-top size" alt="..." />
      //     <div className="card-body">
      //       <h3 className="card-title">+200</h3>
      //       <h3 className="card-title">Project Done</h3>
      //     </div>
      //   </div>
      //   <div className="card text-white c2">
      //     <img src={img5} className="img-fluid card-img-top size" alt="..." />
      //     <div className="card-body">
      //       <h3 className="card-title">+100</h3>
      //       <h3 className="card-title">Client</h3>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default HomeCard;
