import React, { Component } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import Map from '../../Components/Map/map.component'
import Joi from 'joi-browser';
import './contactUs.styles.css'


class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                company: '',
                name: '',
                job: '',
                email: '',
                phone: '',
                subject: '',
                message: ''
            },
            errors: {},
            pageHeader: {
                title: 'Contact us',
                breadcrumb: "Contact us"
            }
        }
        this.schema = {
            company: Joi.string().allow('').optional().label('Company'),
            name: Joi.string().required().label('Name'),
            job: Joi.string().required().label('Job Title'),
            email: Joi.string().required().email().label('Email'),
            phone: Joi.number().integer().positive().required().label('Phone'),
            subject: Joi.string().required().label('Subject'),
            message: Joi.string().required().label('Message')

        }
        this.handleSendEmail = this.handleSendEmail.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
        this.validateProperty = this.validateProperty.bind(this);
    }
    handleSendEmail(event) {
        event.preventDefault();
        const errors = this.validate();
        if (errors) {
            return this.setState({ errors });
        }

        emailjs.sendForm('sendinblue', 'template_5NU78eGn', event.target, 'user_AV6kA6k1QV0bN6VNUeVjg')
            .then((result) => {
                alert('Your email has been sent successfully!');
                window.location = '/';
                console.log(result.text);
            }, (error) => {
                alert('Some thing error happend Please try again!');
                console.log(error.text);
            });
    }
    handleChange({ currentTarget: input }) {
        const errors = { ...this.state.errors };
        if (errors[input.name]) {
            const errorMessage = this.validateProperty(input);
            if (errorMessage) errors[input.name] = errorMessage;
            else delete errors[input.name];
        }
        const data = { ...this.state.data };
        data[input.name] = input.value;
        this.setState({ data, errors });
    }
    validate() {
        const options = { abortEarly: false };
        const { error } = Joi.validate(this.state.data, this.schema, options);
        if (!error) return null;

        const errors = {};
        for (let item of error.details) {
            errors[item.path[0]] = item.message;
        }
        return errors;
    }
    validateProperty({ name, value }) {
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null;
    };
    state = {}
    render() {
        const { company, name, job, email, phone, subject, message } = this.state.data;
        const { name: nameError, email: emailError, phone: phoneError, subject: subjectError, message: messageError } = this.state.errors;
        return (
            <div className="contact-us-container">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Map />
                        </Col>
                        <Col md={12} className="mt-5">
                            <h2 className='text-left text-white'>TALK TO US</h2>
                            <form name="frmContact" onSubmit={this.handleSendEmail}>
                                <div className='row'>
                                    <Col xs={12} sm={12} md={6} xl={4}>
                                        <Form.Group className="group">
                                            <Form.Label htmlFor="name" className={`${nameError && 'error-lable'}`}>Name*</Form.Label>
                                            <Form.Control id="name" name="name" type="text" className={`${nameError && 'error-input'}`} value={name} placeholder="Full Name" onChange={this.handleChange}></Form.Control>
                                            {nameError && <div className="alert alert-danger error-container">{nameError}</div>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} xl={4}>
                                        <Form.Group className="group">
                                            <Form.Label htmlFor="company" >Company</Form.Label>
                                            <Form.Control id="company" name="company" type="text" value={company} placeholder="Company Name" onChange={this.handleChange}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} xl={4}>
                                        <Form.Group className="group">
                                            <Form.Label htmlFor="job" >Job Title</Form.Label>
                                            <Form.Control id="job" name="job" type="text" value={job} placeholder="Job Title" onChange={this.handleChange}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} xl={4}>
                                        <Form.Group className="group">
                                            <Form.Label htmlFor="email" className={`${emailError && 'error-lable'}`}>Email*</Form.Label>
                                            <Form.Control id="email" name="email" type="email" className={`${emailError && 'error-input'}`} value={email} placeholder="Email" onChange={this.handleChange}></Form.Control>
                                            {emailError && <div className="alert alert-danger error-container">{emailError}</div>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} xl={4}>
                                        <Form.Group className="group">
                                            <Form.Label htmlFor="phone" className={`${phoneError && 'error-lable'}`}>Phone*</Form.Label>
                                            <Form.Control id="phone" name="phone" type="text" className={`${phoneError && 'error-input'}`} value={phone} placeholder="Phone" onChange={this.handleChange}></Form.Control>
                                            {phoneError && <div className="alert alert-danger error-container">{phoneError}</div>}
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} sm={12} md={6} xl={4}>
                                        <Form.Group className="group">
                                            <Form.Label htmlFor="subject" className={`${subjectError && 'error-lable'}`}>Subject*</Form.Label>
                                            <Form.Control id="subject" name="subject" as="select" className={`${subjectError && 'error-input'}`} value={subject} onChange={this.handleChange}>
                                                <option value=''>Talk to us about</option>
                                                <option value='General enquiries'>General enquiries</option>
                                                <option value='New business enquiries'>New business enquiries</option>
                                                <option value='Media enquiries'>Media enquiries</option>
                                                <option value='Industry suppliers enquiries'>Industry suppliers enquiries</option>
                                                <option value='Joining our team'>Joining our team</option>
                                                <option value='Internship Programs'>Internship Programs</option>
                                                <option value='Other'>Other</option>
                                            </Form.Control>
                                            {subjectError && <div className="alert alert-danger error-container">{subjectError}</div>}
                                        </Form.Group>
                                    </Col>

                                </div>
                                <Form.Group className='group' >
                                    <Form.Label htmlFor="message" className={`${messageError && 'error-lable'}`}>Messgae*</Form.Label>
                                    <Form.Control id="message" name="message" as="textarea" className={`${messageError && 'error-input'}`} value={message} placeholder='Message' onChange={this.handleChange} />
                                    {messageError && <div className="alert alert-danger error-container">{messageError}</div>}
                                </Form.Group>
                                <Form.Group className="">
                                    <Button type="submit" className="btn" style={{ paddingTop: 10, paddingBottom: 0 }}>Send</Button>
                                </Form.Group>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default ContactUs;