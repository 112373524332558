import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SliderCustomPaging from '../../Components/SliderCustomPaging/sliderCustomPaging.component';
import Projectdetails from '../../Components/ProjectDetails/projectDetails.component';

class ProjectSingle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageHeader: {
                image: props.location.state.inner_image,
                title: props.location.state.title,
                breadcrumb: props.location.state.breadcrumb,
                description: props.location.state.description
            }
        }
    }
    render() {
        return (
            <section className="project-info mb-5 mt-3">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <SliderCustomPaging data={this.props.location.state} />
                        </Col>
                    </Row>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <Projectdetails data={this.props.location.state} />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </section>
        );
    }
}

export default ProjectSingle;