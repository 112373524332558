import React, { Component } from "react";
import { Row } from "react-bootstrap";

import imgAboutUs from "../images/aboutUsSection.jpg";
import imgProject1 from "../images/project1.png";
import imgProject2 from "../images/project2.png";
import imgProject3 from "../images/project3.png";
import imgProject4 from "../images/project4.png";
import imgProject5 from "../images/project5.png";

class HomeProjects extends Component {
  render() {
    return (
      <div>
        <div className="text-center page-header-container pt-5">
          <h1>Our latest projects</h1>
        </div>

        {/* <div className="container-fluid">
          <div className="row no-gutters">
            <div className="col-4">
              <img src={imgProject2} className="img-fluid" alt="..." />
            </div>
            <div className="col-4">
              <img src={imgProject2} className="img-fluid" alt="..." />
            </div>
            <div className="col-4">
              <img src={imgProject2} className="img-fluid" alt="..." />
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-4 c1 p-5">
              <h2>ANAKAJI</h2>
              <p>
                DMA We was established in 1989, as a diversified architectural
                firm and as the corner-stone for the DMG foundation; one of the
                leading entities in Real Estate Development in Egypt.
              </p>
            </div>
            <div className="col-4 c2 p-5">
              <h2>ANAKAJI</h2>
              <p>
                DMA We was established in 1989, as a diversified architectural
                firm and as the corner-stone for the DMG foundation; one of the
                leading entities in Real Estate Development in Egypt.
              </p>
            </div>
            <div className="col-4 c3 p-5">
              <h2>ANAKAJI</h2>
              <p>
                DMA We was established in 1989, as a diversified architectural
                firm and as the corner-stone for the DMG foundation; one of the
                leading entities in Real Estate Development in Egypt.
              </p>
            </div>
          </div>
        </div> */}

        <div className="card-columns p-5 .col-container">
          <div className="card h-600">
            <img src={imgProject1} className="card-img-top" alt="..." />
          </div>

          <div className="card p-3 c1 p-5 h-560">
            <div className="card-body">
              <h1 className="card-title">Sun Capital</h1>
              <p className="card-text">The site is located in Giza governorate, in the region of Greater Cairo, on 557.59 Feddan (about 2,341,886.75 sq. meters). It is near the Egyptian Museum, overlooks the Giza Pyramids in the north east direction, bordered by the Cairo-Fayoum highway near Oasis road which leads to Sixth of October City, and also near the Cairo ring road.</p>
            </div>
            {/* <footer>
              <small className="text-muted">
                <a
                  href="/#/Projects/75"
                  className="btn btn-secondary my-0"
                >Read more</a>
              </small>
            </footer> */}
          </div>

          <div className="card">
            <img src={imgProject2} className="card-img-top " alt="..." />
          </div>

          <div className="card p-3 c3 p-5">
            <div className="card-body">
              <h1 className="card-title">Icity New Cairo</h1>
              <p className="card-text">
              At I City, we have created an environment that gives you the possibility to indulge in everything you wish for, without any compromise.
              </p>
            </div>
            {/* <footer>
              <small className="text-muted">
                <a
                  href="/#/Projects/5"
                  className="btn btn-secondary my-0"
                >Read more</a>
              </small>
            </footer> */}
          </div>

          <div className="card">
            <img src={imgProject4} className="card-img-top" alt="..." />
          </div>

          <div className="card p-3 c2 p-5 h-560">
            <div className="card-body">
              <h1 className="card-title text-white">IL Bosco</h1>
              <p className="card-text">IL BOSCO designed on a contemporary style base. The design was based on making a green environment and good scenery. The project accommodates different types of residential units from standalone villas, twin houses, apartments and Forest Tower in addition to the clubhouse, commercial</p>
            </div>
            {/* <footer>
              <small className="text-muted">
                <a
                  href="/#/Projects/17"
                  className="btn btn-secondary my-0"
                >Read more</a>
              </small>
            </footer> */}
          </div>

          <div className="card h-600">
            <img src={imgProject5} className="card-img-top" alt="..." />
          </div>
        </div>
      </div>
    );
  }
}

export default HomeProjects;
