import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import History from './history.page';
import OverViewPage from './overview.page';
import Collabration from './collabration.page';
import "animate.css/animate.min.css";

class AboutDma extends Component {
    constructor() {
        super();
        this.state = {
            pageHeader: {
                title: 'About DMA',
                breadcrumb: 'About',
                viewNeeded: <OverViewPage />,
                overviewActive: " active",
                historyActive: "",
                ourPplActive: "",
                collabrationActive: ""
            }
        }
    }

    componentDidMount() {
        this.setState({ viewNeeded: <OverViewPage />, overviewActive: " active", historyActive: "", ourPplActive: "", collabrationActive: "" })
    }

    render() {
        return (
            <div>
                <div className="about-content mt-5 mb-5">
                    <Container fluid className="my-5 px-md-5">
                        <Row className="mb-5">
                            <Col md={12}>
                                <Row className={"text-left"}>
                                    <Col md={2}>
                                        <h3><a className={"href cursor" + this.state.overviewActive} onClick={() => this.setState({ viewNeeded: <OverViewPage />, overviewActive: " active", historyActive: "", ourPplActive: "", collabrationActive: "" })}>{"Overview"}</a></h3>
                                    </Col>
                                    <Col md={2}>
                                        <h3><a className={"href cursor" + this.state.historyActive} onClick={() => this.setState({ viewNeeded: <History />, overviewActive: "", historyActive: " active", ourPplActive: "", collabrationActive: "" })}>{"History"}</a></h3>
                                    </Col>
                                    <Col md={2}>
                                        <h3><a href="/#People/" className="href cursor">Our people</a></h3>
                                    </Col>
                                    <Col md={2}>
                                        <h3><a className={"href cursor" + this.state.collabrationActive} onClick={() => this.setState({ viewNeeded: <Collabration />, overviewActive: "", historyActive: "", ourPplActive: "", collabrationActive: " active" })}>{"Collabration"}</a></h3>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {this.state.viewNeeded}

                    </Container>
                </div>
            </div>
        );
    }
}

export default AboutDma;
