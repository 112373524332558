import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { postFullTimePositionAction } from '../../Redux/Actions/data.actions';

class FullTimePosition extends Component {

    constructor() {
        super();
        this.state = {
            formData: {
                email: "",
                f_name: "",
                l_name: "",
                education: "",
                experience: "",
                p_experience: "",
                current_title: "",
                c_skills: "",
                trainig: "",
                job_required: "",
                cv_attached: ""
            },
            reuest_for: "full_time_position",
            form_reponse: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState(prevState => ({formData:{...prevState.formData,[name]: value}}));
    }

    handleSubmit(event) {
        event.preventDefault();
        let postData = this.state.formData;
        const response = async () => {await this.props.dispatch(postFullTimePositionAction(postData)).then(res => res.payload)};
        console.log(response);
    }

    render() {
        return (
            <form method="POST" onSubmit={this.handleSubmit}>
                <Row className={"pt-5"}>
                    <Col md={8}>
                        <div className={"row"}>
                            <div className={"col-12 col-md-3 text-left text-md-right"}>
                                <label htmlFor="email">E-mail: </label>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <input type="email" className={"form-control"} name={"email"} id={"email"} value={this.state.email} onChange={this.handleInputChange}/>
                            </div>
                        </div>
                        <div className={"row mt-3"}>
                            <div className={"col-12 col-md-3 text-left text-md-right"}>
                                <label htmlFor="f_name">First name: </label>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <input type="text" className={"form-control"} name={"f_name"} id={"f_name"} value={this.state.f_name} onChange={this.handleInputChange}/>
                            </div>
                        </div>
                        <div className={"row mt-3"}>
                            <div className={"col-12 col-md-3 text-left text-md-right"}>
                                <label htmlFor="l_name">Last name: </label>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <input type="text" className={"form-control"} name={"l_name"} id={"l_name"} value={this.state.l_name} onChange={this.handleInputChange}/>
                            </div>
                        </div>
                        <div className={"row mt-3"}>
                            <div className={"col-12 col-md-3 text-left text-md-right"}>
                                <label htmlFor="education">Education: </label>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <input type="text" className={"form-control"} name={"education"} id={"education"} value={this.state.education} onChange={this.handleInputChange}/>
                            </div>
                        </div>
                        <div className={"row mt-3"}>
                            <div className={"col-12 col-md-3 text-left text-md-right"}>
                                <label htmlFor="experience">Years of experience: </label>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <input type="text" className={"form-control"} name={"experience"} id={"experience"} value={this.state.experience} onChange={this.handleInputChange}/>
                            </div>
                        </div>
                        <div className={"row mt-3"}>
                            <div className={"col-12 col-md-3 text-left text-md-right"}>
                                <label htmlFor="p_experience">Profession of experience: </label>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <input type="text" className={"form-control"} name={"p_experience"} id={"p_experience"} value={this.state.p_experience} onChange={this.handleInputChange}/>
                            </div>
                        </div>
                        <div className={"row mt-3"}>
                            <div className={"col-12 col-md-3 text-left text-md-right"}>
                                <label htmlFor="current_title">Current job title: </label>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <input type="text" className={"form-control"} name={"current_title"} id={"current_title"} value={this.state.current_title} onChange={this.handleInputChange}/>
                            </div>
                        </div>
                        <div className={"row mt-3"}>
                            <div className={"col-12 col-md-3 text-left text-md-right"}>
                                <label htmlFor="c_skills">Computer skills: </label>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <input type="text" className={"form-control"} name={"c_skills"} id={"c_skills"} value={this.state.c_skills} onChange={this.handleInputChange}/>
                            </div>
                        </div>
                        <div className={"row mt-3"}>
                            <div className={"col-12 col-md-3 text-left text-md-right"}>
                                <label htmlFor="trainig">Training courses: </label>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <input type="text" className={"form-control"} name={"trainig"} id={"trainig"} value={this.state.trainig} onChange={this.handleInputChange}/>
                            </div>
                        </div>
                        <div className={"row mt-3"}>
                            <div className={"col-12 col-md-3 text-left text-md-right"}>
                                <label htmlFor="job_required">Job required: </label>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <input type="text" className={"form-control"} name={"job_required"} id={"job_required"} value={this.state.job_required} onChange={this.handleInputChange}/>
                            </div>
                        </div>
                        <div className={"row mt-3"}>
                            <div className={"col-12 col-md-3 text-left text-md-right"}>
                                <label htmlFor="cv_attached">Attach CV: </label>
                            </div>
                            <div className={"col-12 col-md-6"}>
                                <input type="file" className={"form-control"} name={"cv_attached"} id={"cv_attached"} value={this.state.cv_attached} onChange={this.handleInputChange}/>
                            </div>
                        </div>
                        <div className={"row mt-2 mt-md-1"}>
                            <div className={"col-12 col-md-6 offset-md-3 text-left color_active"}>
                                <label>* Kindly be informed that CV must include a recent photo</label>
                            </div>
                        </div>
                        <div className={"row mt-3"}>
                            <div className={"col-12 col-md-6 offset-md-3 text-right"}>
                                <input type={"submit"} className={"btn btn btn-primary"} style={{ paddingTop: 10, paddingBottom: 0 }} value={"Submit"}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        );
    }
}

export default FullTimePosition;
