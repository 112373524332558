import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import './map.styles.css';

const Marker = props => {
  return (
    <div>
      <div className="pin"></div>
      <div className="pulse"></div>
    </div>
  )
}
 
class Map extends Component {
  static defaultProps = {
    center: {
      lat: 30.092165,
      lng: 31.3725723
    },
    zoom: 19
  };
  
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '313px', width: '100%' ,border: '3px solid white' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ 
              key:'AIzaSyAa0pPm2VRLV9DVSFHwfsk6cbpDL69tJwA'
            }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <Marker lat={30.092165} lng={31.3725723} text="DMA" />
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;