import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { getAllCareersAction } from '../../Redux/Actions/data.actions';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'animate.css/animate.min.css';

const loader = {
    margin: '3rem',
    transition: '3s'
}
class Careers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            position: '',
            service: '',
            allCareers: []
        }
    }
    async componentDidMount() {
        this.props.getCareers();
        this.setState({ allCareers: await this.props.getCareers().then(res => res.payload) }, () => {
            console.log('state: ', this.state);
            console.log(this.state.allCareers.length);
        })
    }
    render() {
        const { loading } = this.props;
        if (loading) {
            return (
                <Loader type='Oval' color='#010039' height={100} width={100} style={loader} />
            );
        }
        let count = 0;
        return (
            this.state.allCareers.map((item, index) => {
                count++;
                return (
                    <tr key={index}>
                      <td>{item.position}</td>
                      <td>{item.date}</td>
                    </tr>
                );
            })
        );
    }
}

const mapStateToProps = state => ({
    careers: state.careers,
    loading: state.loading
})

const mapDispatchToProps = dispatch => ({
    getCareers: () => dispatch(getAllCareersAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Careers);
