import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import SecondImage from './images/history_2.png';
import ThirdImage from './images/history_3.png';
import FourthImage from './images/history_4.png';
import FifithImage from './images/history_5.png';
import SixthImage from './images/history_6.png';
import SeventhImage from './images/history_7.png';

class OverViewPage extends Component {

    constructor() {
        super();
    }

    render() {
        return (
            <Row>
                <Col md={12} className={"my-5"}>
                    <img src={SecondImage} alt="Overview" style={{width: '100%'}}/>
                </Col>

                <Row className={"my-5 pt-5"}>
                    <Col md={10} className={"row offset-1"}>
                        <div className={"orangeBg"} style={{ width: 40 }}></div>
                        <Col md={10}>
                            <p className={"h1 b text-left pt-4"}>
                                HOW WE WORK
                            </p>
                        </Col>
                        <Col md={12} className={"pt-4 mt-3"} style={{ marginLeft: 40 }}>
                            <p className={"h3 b text-left"}>
                                We go deeply in life to envisage the usage of the designed facility or development on hand Design refescts clearly the need and requirments of the facility user whether it is a mixed-use development, a reidential complex or an office building, we cisualized and design it “ inside-out”
                            </p>
                            <p className={"h3 b text-left"}>
                                DMA family is made up of over 250 proffesional and adminstrations creating an integrated network of dedicated talents that make DMA distinctive in whta they offer. At DMA we believe that architectural Design Is not only about being iconic or unique; it is about bringing the project to life.
                            </p>
                        </Col>
                    </Col>
                </Row>

                <Row className={"mt-5 justify-content-center col-12"}>
                    <Col md={6} className={"row"}>
                        <img src={ThirdImage} alt="Overview"  style={{width: '100%'}}/>
                    </Col>
                    <Col md={6}>
                        <img src={FourthImage} alt="Overview"  style={{width: '100%'}}/>
                    </Col>
                </Row>

                <Row md={12} className={"mt-5 justify-content-center"}>
                    <Col md={6} className={"greyBg row pt-5"}>
                        <div className={"orangeBg offset-3"} style={{ width: 40, height: 100 }}></div>
                        <Col md={8} className={"h-25"}>
                            <p className={"h1 b text-left py-5"}>
                                OUR MISSION
                            </p>
                        </Col>
                        <Col md={10} className={"mt-5 offset-1"}>
                            <p>Together we bring life to land improve living commuities earn respect of our stakeholders & become their ultimate choice.</p>
                        </Col>
                    </Col>
                    <Col md={6}>
                        <img src={FifithImage} alt="Overview"  style={{width: '100%'}}/>
                    </Col>
                </Row>

                <Row className={"mt-5"}>
                    <Col md={6}>
                        <img src={SixthImage} alt="Overview"  style={{width: '100%'}}/>
                    </Col>
                    <Col md={6} className={"greyBg row pt-5"}>
                        <div className={"orangeBg offset-3"} style={{ width: 40, height: 100 }}></div>
                        <Col md={8} className={"h-25"}>
                            <p className={"h1 b text-left py-5"}>
                                OUR VISION
                            </p>
                        </Col>
                        <Col md={10} className={"mt-5 offset-1"}>
                            <p>Leadership with a strongly rooted organization, building landmarks & standing as a beacon of khair, pridingourselves & our future generation.</p>
                        </Col>
                    </Col>
                </Row>

                <Row className={"mt-5"}>
                    <Col md={6} className={"greyBg row pt-5"}>
                        <div className={"orangeBg offset-3"} style={{ width: 40, height: 100 }}></div>
                        <Col md={8} className={"h-25"}>
                            <p className={"h1 b text-left py-5"}>
                                OUR VALUES
                            </p>
                        </Col>
                        <Col md={10} className={"mt-5 offet-1"}>
                            <p>Guidance Growth Focus Social Responsibilites Integrity Quality</p>
                        </Col>
                    </Col>
                    <Col md={6}>
                        <img src={SeventhImage} alt="Overview"  style={{width: '100%'}}/>
                    </Col>
                </Row>

                <Row className={"mt-5 greyBg"}>
                    <Col md={10} className={"row pt-5"}>
                        <div className={"orangeBg offset-1"} style={{ width: 40, height: 100 }}></div>
                        <Col md={8}>
                            <p className={"h1 b text-left pt-5"}>
                                WHAT DMA CAN PROVIDE
                            </p>
                        </Col>
                        <Col md={12} className={"text-left mt-5 offset-1 pl-5"}>
                            <p>DMA has an in house design and engineering team members in 8 different disciplines, which allow sharing a diverse range of experience, we have the capabilities and resources to lead projects of nearly any scale. Our multidisciplinary, stanalone support based on your individual needs by providing different design and engineering services.</p>
                        </Col>
                    </Col>
                </Row>

                <Row className={"mt-5 greyBg"}>
                    <Col md={6} className={"row pt-5"}>
                        <div className={"orangeBg offset-1"} style={{ width: 40, height: 100 }}></div>
                        <Col md={8}>
                            <p className={"h1 b text-left pt-4"}>
                                DMA CONSULTANCY SERVICE
                            </p>
                        </Col>
                        <Col md={12} className={"text-left mt-5 offset-1 pl-5"}>
                            <p>Concept Design Schematic Design and Bodr Detailed Design Drawings Tender Drwaings / Documents Tender Evaluation Construction Drawings</p>
                        </Col>
                    </Col>
                </Row>

                <Row className={"mt-5 justify-content-center col-12"}>
                    <Col md={12} className={"row pt-5 text-center justify-content-center"}>
                        <div className={"orangeBg offset-1"} style={{ width: 40, height: 100 }}></div>
                        <Col md={3}>
                            <p className={"h1 b text-left pt-5"}>
                                OUR Services
                            </p>
                        </Col>
                    </Col>
                </Row>

                <Row className={"mt-5"}>
                    <Col md={6} className={"row pt-5"}>
                        <div className={"orangeBg offset-1"} style={{ width: 40, height: 100 }}></div>
                        <Col md={8}>
                            <p className={"h1 b text-left pt-5"}>
                                ARCHITECTURE
                            </p>
                        </Col>
                        <Col md={10} className={"mt-5 text-left pl-5 offset-1"}>
                            <p>With our extensive experience in all types of buildings. We provide a complete architecture services by combinding architectural vision with multidisciplinary site election, planning, engineering and interior design expertise. Applying green archietecture concepts to help sustain the environment and improve the way of living .</p>
                        </Col>
                    </Col>
                    <Col md={6} className={"row pt-5"}>
                        <div className={"orangeBg offset-1"} style={{ width: 40, height: 100 }}></div>
                        <Col md={8}>
                            <p className={"h1 b text-left pt-5"}>
                                INTERIOR
                            </p>
                        </Col>
                        <Col md={10} className={"mt-5 text-left pl-5 offset-1"}>
                            <p>DMA interior design department is a direct extension of our architectural division. The interior team consistently delivers innovative design concepts. Over the past few years, our interior designers have worked across commercial, cultural, and residential projects. </p>
                        </Col>
                    </Col>
                </Row>

                <Row className={"mt-5"}>
                    <Col md={6} className={"row pt-5"}>
                        <div className={"orangeBg offset-1"} style={{ width: 40, height: 100 }}></div>
                        <Col md={8}>
                            <p className={"h1 b text-left pt-4"}>
                                MASTER PLANNING & LANDSCAPE
                            </p>
                        </Col>
                        <Col md={10} className={"mt-5 text-left pl-5 offset-1"}>
                            <p>We believe that Master planning must be both visionary and practical to achieve full development potential in addition to understanding the financial cost and phasing implications.
We explore landscape planning in a combination of both hard and soft zones. Our landscape architects play an integral role throughout the design process, they shape environments that connect people to each other and to the world around them, in addition to blending an imaginative design gestures with natural systems to establish a sense of place.</p>
                        </Col>
                    </Col>
                    <Col md={6} className={"row pt-5"}>
                        <div className={"orangeBg offset-1"} style={{ width: 40, height: 100 }}></div>
                        <Col md={8}>
                            <p className={"h1 b text-left pt-5"}>
                                Structure
                            </p>
                        </Col>
                        <Col md={10} className={"mt-5 text-left pl-5 offset-1"}>
                            <p>The structure team is engaged from the early stages of design, helping the architects choose the optimum structural solutions that serve the clients vision and the design intent while keeping economy and cost efficiency in perspective. The team at DMA is hand-picked to fit certain criteria including, but not limited to, the knowledge of international codes, sharp engineering skills and a drive for continuous learning. Members of the structural department have acquired a wide range of expertise in conventional and non-conventional structural solutions and always strive for knowledge of emerging trends in their field.</p>
                        </Col>
                    </Col>
                </Row>

                <Row className={"mt-5"}>
                    <Col md={6} className={"row pt-5"}>
                        <div className={"orangeBg offset-1"} style={{ width: 40, height: 100 }}></div>
                        <Col md={8}>
                            <p className={"h1 b text-left pt-4"}>
                                MEP & INFRASTRUCTURE
                            </p>
                        </Col>
                        <Col md={10} className={"mt-5 text-left pl-5 offset-1"}>
                            <p>Our approach integrates designing environmental and economic challenges to reduce costs and provide long-term, sustainable solutions while preparing our clients objectives, Our Engineering teams from Infrastructure and MEP supports clients through all stages of the project by collaborating with our architectural colleagues to enhance the project’s vision, we contribute to create high-performance projects, We are always mindful to our client’s needs.</p>
                        </Col>
                    </Col>
                    <Col md={6} className={"row pt-5"}>
                        <div className={"orangeBg offset-1"} style={{ width: 40, height: 100 }}></div>
                        <Col md={8}>
                            <p className={"h1 b text-left pt-4"}>
                                Construction Supervision
                            </p>
                        </Col>
                        <Col md={10} className={"mt-5 text-left pt-4 pl-5 offset-1"}>
                            <p>DMA construction supervision team understand construction methodologies from every level which allows them to provide highly qualified, accurate, and time-efficient supervision services to support and enhance project execution, making sure to deliver with quality, schedule and budget, Our goal is to help clients make confident decisions more consistently, easily and quickly, and to meet their expectations.</p>
                        </Col>
                    </Col>
                </Row>
            </Row>
        );
    }
}

export default OverViewPage;
