import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect} from 'react-redux';
import {getNewsAction, getNewsFilterAction} from '../../Redux/Actions/data.actions';
import { Link } from "react-router-dom";
import Loader from 'react-loader-spinner';
import ScrollAnimation from "react-animate-on-scroll";
import "../Filter/filter.styles.css";
import "animate.css/animate.min.css";
import './filterNew.styles.css';
const loader = {
  margin: '3rem',
  transition: '3s'
}
class FilterNews extends Component {
  constructor(props) {
    super();
    this.state = {
      filterList: [],
      news: [],
      filteredCards: [],
      loadeing : true,
      filters: {},
      sortBy: ''
    };
    this.handelFilterChange = this.handelFilterChange.bind(this);
    this.handleSortingChange = this.handleSortingChange.bind(this);

  }
  async componentDidMount() {
    this.props.getFilterNews();
    this.setState({
      news: await this.props.getNews().then(res => res.payload),
      filteredCards: await this.props.getNews().then(res => res.payload),
      filterList: await this.props.getFilterNews().then(res => res.payload),
      filters: await this.props.getFilterNews().then( res => this.mapPropsToObj(res.payload[0]))
    })
      const filter = this.props.data;
      const news = this.state;
      if (filter) {
          const filteredCards = news.filter(news => {
          for (let key in filter) {
              if (news[key] === undefined || news[key] !== filter[key])
              return false;
          }
          return true;
          });
          this.setState({ filteredCards });
      }
  }
  handelFilterChange({currentTarget}) {
    const {name, value} = currentTarget;
    let state = {...this.state}
    state.filters[name] = value;
    this.setState({filters: state.filters}, () => {
      if(value.includes('..')) state.filteredCards = state.news;
      else {
        state.filteredCards = state.news.filter(news => {
          let isValid = false;
          Object.keys(state.filters).forEach(key => {
            if (news[key] !== undefined && news[key] === state.filters[key]) isValid=true;
          })
          return isValid;
          
        });
      }
      
      this.setState({...state});
    })
    
    
  }
  mapPropsToObj(object) {
    let obj = {};
    for (let prop in object) obj[prop] = '';
    return obj
  }
  handleSortingChange({currentTarget}) {
    const { value} = currentTarget;
    const state ={...this.state};
    state.sortBy = value
    if(value === "Oldest") {
      state.news.sort((x , y) =>  new Date(x.date) - new Date(y.date));
    } else {
      state.news.sort((x , y) =>  new Date(y.date) - new Date(x.date))
    }
    this.setState({...state});

  }

  render() {
    if (this.props.loading) {
          return (
              <Loader type='Oval' color='#010039' height={100} width={100} style={loader} />
          );
      }
    return (
      <div>
        <Container fluid>
          <div className="filters">
            {this.state.filterList.length > 0 && Object.keys(this.state.filterList[0]).map((select, index) => {
              return (
                <select key={index} name={select} className="form-control news-filter" value={this.state.filters[select]} onChange={this.handelFilterChange}>
                  <option >{select}..</option>
                  {this.state.filterList.length > 0 && this.state.filterList[0][select].map((option, index) => {
                    return <option key={index} value={option}>{option}</option>;
                  })}
                </select>
              );
            })}
            <select  name="sortBy" className="form-control news-filter" value={this.state.sortBy} onChange={this.handleSortingChange}>
              <option value="" >sortBy...</option>
              <option value="Oldest">Oldest</option>
              <option value="Newest">Newest</option>
            </select>
          </div>
        </Container>
        <div className="projects-items ">
          <Container fluid>
            <Row>
              {this.state.filteredCards.length > 0 && this.state.filteredCards.map((news, index) => {
                return (
                  <Col key={index} xs={12} sm={12} md={6} lg={4}>
                    <ScrollAnimation animateIn="fadeIn">
                      <div className="card-border">
                          <div className="card-container">
                          <img src={news.inner_image} alt="news" className="card-img"/>
                              <div className="sub-container">
                                  <span>{news.date}</span>
                                  <div>
                                      <h1>{news.title}</h1>
                                      <span>{news.category}</span>
                                  </div>
                                  <Link
                                    style={{ paddingTop: 15, paddingRight: 10, paddingLeft: 10 }}
                                    to={{
                                      pathname: "/NewsSingle",
                                      state: {
                                        title: news.title,
                                        descriprion: news.description,
                                        pageHeader: {
                                          image: news.inner_image,
                                          title: "",
                                          description: ""
                                        }
                                      }
                                    }}
                                  >
                                    Show More
                                  </Link>
                              </div>
                          </div>
                      </div>
                    </ScrollAnimation>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
      news: state.news,
      newsFilter: state.newsFilter,
      loading: state.loading,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      getNews: () => dispatch(getNewsAction()),
      getFilterNews: () => dispatch(getNewsFilterAction())
  }   
  
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterNews) ;
