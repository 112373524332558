import http from './http.service';
import { apiUrl } from '../Config/config.json';
import {
    getAllProjectsAction,
    getClientsAction,
    getFilterListAction,
    getWhatWeDoAction,
    getAllNewsAction,
    getNewsFilterListAction,
    getPeopleAction,
    getSliderAction,
    getScopesAction,
    getSectorsAction,
    getLatesWorkAction,
    getAllCareersAction,
    postFullTimePositionAction,
    postInternShipAction
} from '../Config/apiConstantRouts';

export async function getProjects() {
    return await http.get(`${apiUrl}?${getAllProjectsAction}`);
}
export async function getClients() {
    return await http.get(`${apiUrl}?${getClientsAction}`);
}
export async function getFilterList() {
    return await http.get(`${apiUrl}?${getFilterListAction}`);
}
export async function getWhatWeDo() {
    return await http.get(`${apiUrl}?${getWhatWeDoAction}`);
}
export async function getNews() {
    return await http.get(`${apiUrl}?${getAllNewsAction}`);
}
export async function getNewsFilter() {
    return await http.get(`${apiUrl}?${getNewsFilterListAction}`);
}
export async function getPeople() {
    return await http.get(`${apiUrl}?${getPeopleAction}`);
}
export async function getSlider() {
    return await http.get(`${apiUrl}?${getSliderAction}`);
}
export async function getScopes() {
    return await http.get(`${apiUrl}?${getScopesAction}`);
}
export async function getSectors() {
    return await http.get(`${apiUrl}?${getSectorsAction}`);
}
export async function getLatestWork() {
    return await http.get(`${apiUrl}?${getLatesWorkAction}`);
}
export async function getAllCareers() {
    return await http.get(`${apiUrl}?${getAllCareersAction}`);
}
export async function postInternShip(data) {
    return await http.post(`${apiUrl}?${postInternShipAction}`, { data: data });
}
export async function postFullTimePosition(data) {
    const config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    }
    return await http.post(`${apiUrl}?${postFullTimePositionAction}`, config);
}