import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container, Row} from "react-bootstrap";
import PageHeader from "../../Components/PageHeader/pageHeader.component";
import ScrollAnimation from 'react-animate-on-scroll';
import { getClientsAction } from '../../Redux/Actions/data.actions'
import "animate.css/animate.min.css";
import "./clients.styles.css";

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageHeader: {
                title: 'Our Clients'
            }
        }
    }
    componentDidMount() {
        this.props.getClients();
    }

    render() {
        const { pageHeader } = this.state;
        console.log(this.props.clients);
        return (
            <div>
                <PageHeader data={pageHeader} />
                <div className="clients-content">
                    <Container fluid>
                        <ScrollAnimation animateIn="fadeIn">
                            <Row className="eight-cols">
                                {this.props.clients.map((client, index) => (
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-8th">
                                        <img className="client-img" src={client.image} alt="client" />
                                    </div>
                                ))}
                            </Row>
                        </ScrollAnimation>
                    </Container>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        clients: state.clients
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getClients: () => dispatch(getClientsAction()),
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(Clients);

