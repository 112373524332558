import React, { Component, useState } from "react";
import PageHeader from "../../Components/PageHeader/pageHeader.component";
import Filter from "../../Components/Filter/filter.component";

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageHeader: {
        title: "Our projects",
        breadcrumb: "Projects"
      },
    };
  }

  render() {
    return (
      <>
        <div className="filter-container">
          <PageHeader data={this.state.pageHeader} />
          <Filter />
        </div>
      </>
    );
  }
}
export default Projects;
